import cookie from 'react-cookies'

/**
 * 获取cookie
 * @returns
 */
export const loginUser = () => {
  return cookie.load('paramobj')
}

/**
 * 保存cookie
 * @param {*} refresh_token
 */
export const onLogin = (refresh_token) => {
  cookie.save('paramobj', refresh_token, { path: '/',maxAge: 2592000})
}

/**
 * 删除cookie
 */
export const logout = () => {
  cookie.remove('paramobj')
}
