import { lazy } from 'react'

const router = [
    {
        path: '/',
        component: lazy(() =>
            import('../components/router-root')
        ),
        exact: false,
        children: [
            {
                path: '/admin',
                component: lazy(() =>
                    import('../pages/admin/admin')
                ),
                exact: false,
            },
            {
                path: '/login',
                component: lazy(() => import('../pages/login/login')),
                exact: true,
            },
            {
                path: '/weixin',
                component: lazy(() => import('../weixin/pages/weixin/weixin')),
                exact: false,
            }
        ]
    },
]

export default router
