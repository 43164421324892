import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import store from './redux/store'
import { Provider } from 'react-redux'
import {onLogin} from './utils/cookieUtils'


window.onload = function () {
  const paramobj = {}
  const parameterURL = this.location.search.substring(1, this.location.search.length)
  const paramArr = parameterURL.split("&")
  let temp
  for (let i = 0; i < paramArr.length; i++) {
    temp = paramArr[i].split("=")
    if (temp.length === 1) {
      paramobj[temp[0]] = ""
    }
    else if (temp.length > 1) {
      for (let j = 0; j < temp.length; j++) {
        paramobj[temp[0]] = decodeURIComponent(temp[1])
      }
    }
  }
  onLogin(paramobj)
}




ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
