import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import router from './router'
import './App.css'

function App () {
  return (
    <Router>
      <Suspense fallback={<div style={{ fontSize: 14 }}>Loading...</div>}>
        <Switch>
          {router.map((item) => {
            return (
              <Route
                exact={item.exact}
                path={item.path}
                key={item.path}
                render={(props) => (
                  <item.component {...props} routes={item.children} />
                )}
              />
            )
          })}
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
